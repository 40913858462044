import { Link, LinkProps } from 'react-router-dom';

export const asLinkProps = (to: LinkProps['to']) => ({
  component: Link,
  to,
});

export const hrefLinkProps = (href: string, openNewTab?: boolean) => ({
  href,
  ...(openNewTab && {
    target: '_blank',
    rel: 'noreferrer noopener',
  }),
});
