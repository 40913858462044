import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

/** @jsxImportSource @emotion/react */
import { ClassNames, PropsOf } from '@emotion/react';
import { useMediaQuery, useTheme } from '@mui/material';
import { Box, SystemCssProperties } from '@mui/system';

import {
  CanvasWrapper,
  CircularProgressWithLabel,
  TransformToolbar,
  TransformToolbarProps,
} from '../../components';

export interface CanvasTransformWrapperProps
  extends PropsOf<typeof CanvasWrapper> {
  children?: React.ReactNode;
  canvasChildren?: React.ReactNode;
  loaderMinHeight: SystemCssProperties['minHeight'];
  isPreview?: boolean;
  TransformToolbarProps?: Partial<TransformToolbarProps>;
  onClosePreview?: TransformToolbarProps['onClose'];
  onOpenPreview?: TransformToolbarProps['onOpen'];
  progressValue?: number;
}

const CanvasTransformWrapper = ({
  children,
  canvasChildren,
  loaderMinHeight,
  isPreview,
  onClosePreview,
  onOpenPreview,
  progressValue = 0,
  TransformToolbarProps,
  ...other
}: CanvasTransformWrapperProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

  if (progressValue < 100) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          minHeight: loaderMinHeight,
        }}
      >
        <CircularProgressWithLabel value={progressValue} size="3rem" />
      </Box>
    );
  }

  return (
    <TransformWrapper maxScale={2} disabled={!isPreview}>
      <CanvasWrapper {...other}>
        <ClassNames>
          {({ css }) => (
            <TransformComponent
              wrapperClass={css({
                position: 'relative',
                backgroundColor: theme.palette.grey[100],
              })}
            >
              {children}
            </TransformComponent>
          )}
        </ClassNames>
        {canvasChildren}
        <TransformToolbar
          {...TransformToolbarProps}
          isOpen={isPreview}
          size={isMobile ? 'small' : 'medium'}
          onClose={onClosePreview}
          onOpen={onOpenPreview}
        />
      </CanvasWrapper>
    </TransformWrapper>
  );
};

export default CanvasTransformWrapper;
