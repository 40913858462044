import { styled } from '@mui/material/styles';

const CanvasHotspot = styled('span')({
  pointerEvents: 'none',
  userSelect: 'none',
  maxWidth: '100%',
  maxHeight: '100vh',
  alignSelf: 'flex-start', // iOS height hack.
});

export default CanvasHotspot;
