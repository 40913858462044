import { ListItemButton } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

const DrawerLandingAsideListItem = styled(ListItemButton)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.main, 0.9),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.light, 0.9),
  },
  '&.Mui-focusVisible': {
    backgroundColor: `${alpha(theme.palette.primary.light, 0.9)} !important`,
  },
  '&.Mui-selected': {
    backgroundColor: `${alpha(theme.palette.primary.light, 0.9)} !important`,
  },
}));

export default DrawerLandingAsideListItem;
