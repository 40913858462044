import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

import type { PointHotspotProps } from './PointHotspot.types';

const PointHotspot = styled(Paper, {
  shouldForwardProp: (prop) =>
    prop !== 'colorGreen' && prop !== 'colorOrange' && prop !== 'sizeSmall',
})<PointHotspotProps>(({ theme, sizeSmall, colorOrange, colorGreen }) => ({
  borderRadius: '50%',
  border: `2px solid ${theme.palette.common.white}`,
  padding: theme.spacing(1),
  ...(sizeSmall && {
    padding: theme.spacing(0.65625),
  }),
  ...(colorOrange && {
    backgroundColor: theme.palette.secondary.main,
  }),
  ...(colorGreen && {
    backgroundColor: theme.palette.primary.main,
  }),
}));

export default PointHotspot;
