import { CircularProgress, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { CircularProgressWithLabelProps } from './CircularProgressWithLabel.types';

const CircularProgressWithLabelRoot = styled('div')({
  position: 'relative',
  display: 'inline-flex',
});

const CircularProgressWithLabelTextWrapper = styled('div')({
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

function CircularProgressWithLabel(props: CircularProgressWithLabelProps) {
  return (
    <CircularProgressWithLabelRoot>
      <CircularProgress variant="determinate" {...props} />
      <CircularProgressWithLabelTextWrapper>
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </CircularProgressWithLabelTextWrapper>
    </CircularProgressWithLabelRoot>
  );
}

export default CircularProgressWithLabel;
