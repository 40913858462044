import qs from 'query-string';
import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { ORIENTATION_SEQUENCES } from '../utils';

const DrawerNavLayout = lazy(() => import('../layouts/DrawerNav'));
const UnitFloorPlanLayout = lazy(() => import('../layouts/UnitFloorPlan'));
const UnitLayout = lazy(() => import('../layouts/Unit'));
const UnitVirtualTourLayout = lazy(() => import('../layouts/UnitVirtualTour'));

const Garden = lazy(() => import('../pages/Garden'));
const GardenVirtualTour = lazy(() => import('../pages/GardenVirtualTour'));

const UnitTypeA = lazy(() => import('../pages/UnitTypeA'));
const UnitTypeAFloorPlan = lazy(() => import('../pages/UnitTypeAFloorPlan'));
const UnitTypeAVirtualTour = lazy(
  () => import('../pages/UnitTypeAVirtualTour')
);

const UnitTypeB = lazy(() => import('../pages/UnitTypeB'));
const UnitTypeBFloorPlan = lazy(() => import('../pages/UnitTypeBFloorPlan'));
const UnitTypeBVirtualTour = lazy(
  () => import('../pages/UnitTypeBVirtualTour')
);

const UnitTypeC = lazy(() => import('../pages/UnitTypeC'));
const UnitTypeCVirtualTour = lazy(
  () => import('../pages/UnitTypeCVirtualTour')
);

const UnitTypeD = lazy(() => import('../pages/UnitTypeD'));
const UnitTypeDVirtualTour = lazy(
  () => import('../pages/UnitTypeDVirtualTour')
);

const UnitTypeCDFloorPlan = lazy(() => import('../pages/UnitTypeCDFloorPlan'));

const AppRoutes: React.FC = () => (
  <Routes>
    <Route path="/" element={<DrawerNavLayout />}>
      <Route element={<UnitLayout />}>
        <Route path="type-a">
          <Route path="floor-plan" element={<UnitFloorPlanLayout />}>
            <Route index element={<UnitTypeAFloorPlan />} />
          </Route>
          <Route index element={<UnitTypeA />} />
        </Route>
        <Route path="type-b">
          <Route path="floor-plan" element={<UnitFloorPlanLayout />}>
            <Route index element={<UnitTypeBFloorPlan />} />
          </Route>
          <Route index element={<UnitTypeB />} />
        </Route>
        <Route path="type-c" element={<UnitTypeC />} />
        <Route path="type-d" element={<UnitTypeD />} />
        <Route path="type-c-d/floor-plan" element={<UnitFloorPlanLayout />}>
          <Route index element={<UnitTypeCDFloorPlan />} />
        </Route>
      </Route>
      <Route index element={<Garden />} />
      <Route
        path="*"
        element={
          <Navigate
            to={{
              pathname: '/',
              search: `?${qs.stringify({
                orientation: ORIENTATION_SEQUENCES[0],
              })}`,
            }}
          />
        }
      />
    </Route>
    <Route path="/virtual-tour" element={<GardenVirtualTour />} />
    <Route element={<UnitVirtualTourLayout />}>
      <Route path="/type-a/virtual-tour" element={<UnitTypeAVirtualTour />} />
      <Route path="/type-b/virtual-tour" element={<UnitTypeBVirtualTour />} />
      <Route path="/type-c/virtual-tour" element={<UnitTypeCVirtualTour />} />
      <Route path="/type-d/virtual-tour" element={<UnitTypeDVirtualTour />} />
    </Route>
  </Routes>
);

export default AppRoutes;
