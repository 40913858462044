export const shownHotspotStyles = (
  leftPercentage: number,
  topPercentage: number
) => ({
  opacity: 1,
  left: `${leftPercentage}%`,
  top: `${topPercentage}%`,
});

export const hiddenHotspotStyles = (
  leftPercentage: number,
  topPercentage: number
) => ({
  opacity: 0,
  pointerEvents: 'none',
  left: `${leftPercentage}%`,
  top: `${topPercentage}%`,
});
