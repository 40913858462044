import { List } from '@mui/material';
import { styled } from '@mui/material/styles';

const DrawerLandingAsideList = styled(List)(({ theme }) => ({
  overflow: 'hidden',
  color: theme.palette.common.white,
  borderTopRightRadius: `${(theme.shape.borderRadius as number) * 6}px`,
  borderBottomRightRadius: `${(theme.shape.borderRadius as number) * 6}px`,
  boxShadow: theme.shadows[4],
}));

export default DrawerLandingAsideList;
