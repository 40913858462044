import { Drawer } from '@mui/material';
import { styled } from '@mui/material/styles';

const DrawerLandingAside = styled((props) => (
  <Drawer {...props} variant="permanent" />
))(({ theme }) => ({
  '& .MuiDrawer-paper': {
    marginTop: theme.spacing(8),
    backgroundColor: 'transparent',
    border: 0,
  },
}));

export default DrawerLandingAside;
