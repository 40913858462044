// Orientation is the direction that the viewer is facing.
export const ORIENTATION_SEQUENCES = [
  'north',
  'west',
  'south',
  'east',
] as const;

export const getNextOrientation = (
  currentOrientation: typeof ORIENTATION_SEQUENCES[number]
): typeof ORIENTATION_SEQUENCES[number] => {
  const currentIndex = ORIENTATION_SEQUENCES.indexOf(currentOrientation);
  let newIndex = 0;
  if (currentIndex > -1) {
    newIndex = currentIndex + 1;
    if (newIndex > ORIENTATION_SEQUENCES.length - 1) {
      newIndex = 0;
    }
  }
  return ORIENTATION_SEQUENCES[newIndex];
};

export const getPreviousOrientation = (
  currentOrientation: typeof ORIENTATION_SEQUENCES[number]
): typeof ORIENTATION_SEQUENCES[number] => {
  const currentIndex = ORIENTATION_SEQUENCES.indexOf(currentOrientation);
  let newIndex = 0;
  if (currentIndex > -1) {
    newIndex = currentIndex - 1;
    if (newIndex < 0) {
      newIndex = ORIENTATION_SEQUENCES.length - 1;
    }
  }
  return ORIENTATION_SEQUENCES[newIndex];
};
