import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import type { ButtonImageSliderProps } from './ButtonImageSlider.types';

const PREFIX = 'ButtonImageSlider';
const classes = {
  imageWrapper: `${PREFIX}-imageWrapper`,
  buttonImage: `${PREFIX}-buttonImage`,
};

const ButtonImageSliderImageWrapper = styled('div')({
  overflow: 'hidden',
  pointerEvents: 'none',
  position: 'absolute',
  top: '100%',
  width: '100%',
});

const ButtonImageSliderImage = styled('img')(({ theme }) => ({
  left: 0,
  borderRadius: theme.spacing(0, 0, 1.1, 1.1),
  position: 'absolute',
  top: '-100%',
  transition: theme.transitions.create('top', {
    duration: theme.transitions.duration.complex,
  }),
  width: '100%',
}));

const ButtonImageSliderRoot = styled(Button, {
  shouldForwardProp: (prop) =>
    prop !== 'imgProps' && prop !== 'disableImageSlider',
})(({ disableImageSlider }: ButtonImageSliderProps) => ({
  position: 'relative',
  ...(!disableImageSlider && {
    [`&:hover .${classes.buttonImage}, &.Mui-focusVisible .${classes.buttonImage}`]:
      {
        top: 0,
      },
    [`&:hover .${classes.imageWrapper}, &.Mui-focusVisible .${classes.imageWrapper}`]:
      {
        minHeight: '100vh', // Fix for image overflow on different viewport size.
      },
  }),
}));

function ButtonImageSlider({
  children,
  imgProps,
  ...props
}: ButtonImageSliderProps) {
  return (
    <ButtonImageSliderRoot {...props}>
      {children}
      <ButtonImageSliderImageWrapper className={classes.imageWrapper}>
        <ButtonImageSliderImage
          className={classes.buttonImage}
          alt={imgProps?.alt}
          {...imgProps}
        />
      </ButtonImageSliderImageWrapper>
    </ButtonImageSliderRoot>
  );
}

export default ButtonImageSlider;
