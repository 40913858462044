import { ListSubheader } from '@mui/material';
import { styled } from '@mui/material/styles';

const DrawerLandingAsideListSubheader = styled(ListSubheader)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export default DrawerLandingAsideListSubheader;
