import qs from 'query-string';
import { createContext, useContext, useRef, useState } from 'react';
import {
  matchPath,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { TourGuide, TourGuideType } from '../../components';

export const tourContext = createContext({
  initTour: (type: TourGuideType) => {},
  openTour: (type: TourGuideType) => {},
});

const TourProvider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tourType, setTourType] = useState<TourGuideType | undefined>();

  const hasInitialized = useRef<Record<TourGuideType, boolean>>({
    aerial: false,
    unit: false,
    layer: false,
  });

  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams] = useSearchParams();

  const filter = searchParams.get('filter');

  return (
    <tourContext.Provider
      value={{
        openTour: (type: TourGuideType) => {
          setTourType(type);
          setIsOpen(true);
        },
        initTour: (type: TourGuideType) => {
          if (!hasInitialized.current[type]) {
            setTourType(type);
            setIsOpen(true);
            hasInitialized.current[type] = true;
          }
        },
      }}
    >
      <TourGuide
        isOpen={isOpen}
        tourType={tourType}
        onRequestClose={() => setIsOpen(false)}
        onPointHotspotStep={() => {
          if (
            !Boolean(matchPath('/:unitType', location.pathname)) ||
            (Boolean(matchPath('/:unitType', location.pathname)) &&
              filter !== 'fittings-plus')
          ) {
            navigate({
              pathname: '/type-a',
              search: `?${qs.stringify({ filter: 'fittings-plus' })}`,
            });
          }
        }}
      />
      {children}
    </tourContext.Provider>
  );
};

export const useTourContext = () => useContext(tourContext);

export default TourProvider;
