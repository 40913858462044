import { useCallback, useEffect } from 'react';
import { useTransformContext } from 'react-zoom-pan-pinch';

import {
  Close as CloseIcon,
  ImageSearch as ImageSearchIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
} from '@mui/icons-material';
import { Button, ButtonGroup, Fade, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

import type { TransformToolbarProps } from './TransformToolbar.types';

const TransformToolbarRoot = styled('div', {
  name: 'TransformToolbar',
  slot: 'Root',
})(({ theme }) => ({
  bottom: 0,
  padding: theme.spacing(2.5),
  position: 'absolute',
  zIndex: theme.zIndex.drawer,
}));

const TransformToolbarButton = styled(Button)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
}));

const TransformToolbar = ({
  size,
  isOpen,
  onOpen,
  onClose,
  ButtonGroupProps,
}: TransformToolbarProps) => {
  const transformContext = useTransformContext();

  const handleWindowKeyup = useCallback(
    (e: KeyboardEvent) => {
      if (
        !(e.target instanceof HTMLInputElement) &&
        e.key === 'Escape' &&
        isOpen
      ) {
        transformContext.resetTransform();
        onClose?.(e, 'keyboardShortcut');
      }
    },
    [transformContext, isOpen, onClose]
  );

  useEffect(() => {
    window.addEventListener('keyup', handleWindowKeyup);
    return () => {
      window.removeEventListener('keyup', handleWindowKeyup);
    };
  }, [handleWindowKeyup]);

  return (
    <TransformToolbarRoot>
      <ButtonGroup variant="contained" size={size} {...ButtonGroupProps}>
        <Tooltip title={!isOpen ? 'Preview' : 'Close'}>
          <TransformToolbarButton
            onClick={(e) => {
              transformContext.resetTransform();
              isOpen ? onClose?.(e, 'clickToggleButton') : onOpen?.(e);
            }}
          >
            {!isOpen ? <ImageSearchIcon /> : <CloseIcon />}
          </TransformToolbarButton>
        </Tooltip>
        {isOpen && (
          <Fade in>
            <Tooltip title="Zoom In">
              <TransformToolbarButton onClick={() => transformContext.zoomIn()}>
                <ZoomInIcon />
              </TransformToolbarButton>
            </Tooltip>
          </Fade>
        )}
        {isOpen && (
          <Fade in>
            <Tooltip title="Zoom Out">
              <TransformToolbarButton
                onClick={() => transformContext.zoomOut()}
              >
                <ZoomOutIcon />
              </TransformToolbarButton>
            </Tooltip>
          </Fade>
        )}
      </ButtonGroup>
    </TransformToolbarRoot>
  );
};

export default TransformToolbar;
