import { Backdrop, BackdropProps, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

const BackDropRoot = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: theme.palette.common.white,
}));

export default function BackdropLoader(props: BackdropProps) {
  return (
    <BackDropRoot {...props}>
      <CircularProgress color="inherit" />
    </BackDropRoot>
  );
}
