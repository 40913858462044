import { styled } from '@mui/material/styles';

const CanvasWrapper = styled('section')({
  position: 'relative',
  display: 'flex',
  touchAction: 'none',
  cursor: 'grab',
  '&:active': {
    cursor: 'grabbing',
  },
});

export default CanvasWrapper;
