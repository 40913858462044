import { useState } from 'react';

import { ClickAwayListener, TooltipProps } from '@mui/material';

import StyledTooltip from '../StyledTooltip';
import PointHotspot, { PointHotspotProps } from '../PointHotspot';

const TooltipPointHotspot = ({
  tooltipTitle,
  ...rest
}: PointHotspotProps & { tooltipTitle: TooltipProps['title'] }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <div>
        <StyledTooltip
          title={tooltipTitle}
          open={isOpen}
          onClose={() => setIsOpen(false)}
          PopperProps={{ disablePortal: true }}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          enterTouchDelay={350}
          leaveTouchDelay={6000}
        >
          <PointHotspot
            sx={{
              position: 'absolute',
              transition: (theme) =>
                theme.transitions.create(['left', 'top', 'opacity'], {
                  duration: theme.transitions.duration.shortest,
                }),
            }}
            onClick={() => setIsOpen(true)}
            {...rest}
          />
        </StyledTooltip>
      </div>
    </ClickAwayListener>
  );
};

export default TooltipPointHotspot;
