import { BottomNavigationAction } from '@mui/material';
import { styled } from '@mui/material/styles';

const BottomNavigationActionLanding = styled(BottomNavigationAction)(
  ({ theme }) => ({
    transition: theme.transitions.create('background-color'),
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    '& .MuiBottomNavigationAction-label': {
      color: theme.palette.common.white,
      textAlign: 'center',
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.light,
    },
  })
);

export default BottomNavigationActionLanding;
