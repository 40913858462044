import { BottomNavigation } from '@mui/material';
import { styled } from '@mui/material/styles';

const BottomNavigationLanding = styled(BottomNavigation)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  overflowX: 'auto',
  justifyContent: 'unset',
}));

export default BottomNavigationLanding;
