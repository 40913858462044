import { SvgIcon, SvgIconProps } from '@mui/material';

const IconHouse: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 48.03 42.89">
    <g>
      <g>
        <polygon points="21.33 42.89 3.31 34.61 3.31 13.69 5.11 13.69 5.11 33.46 21.57 41.02 42.1 37.45 42.1 19.53 43.9 19.53 43.9 38.97 21.33 42.89" />
        <polygon points="27.92 27.22 27.92 40.73 36.19 39.29 36.19 25.76 27.92 27.22" />
        <path d="M47.53,20,36.24,8.78,16.6.28a3.47,3.47,0,0,0-4,1L.42,15.69A1.73,1.73,0,0,0,1,18.41L19,26.66a3,3,0,0,0,3.58-.78L33.52,13l9.77,9.89h3.06A1.68,1.68,0,0,0,47.53,20Z" />
        <polygon points="9.92 31.13 15.32 33.59 15.32 28.19 9.92 25.73 9.92 31.13" />
      </g>
    </g>
  </SvgIcon>
);

export default IconHouse;
