import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: alpha(theme.palette.common.black, 0.8),
    maxWidth: 'none',
    [`${theme.breakpoints.up('xs')}`]: {
      ...theme.typography.subtitle1,
    },
  },
}));

export default StyledTooltip;
